import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import ComingSoonView from "../views/ComingSoonView.vue";
import NotFoundView from "../views/NotFoundView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },

  {
    path: "/matrimony",
    name: "matrimony",
    component: ComingSoonView,
  },
  {
    path: "/realestate",
    name: "realestate",
    component: ComingSoonView,
  },
  {
    path: "/vehicles",
    name: "vehicles",
    component: ComingSoonView,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "notFound",
    component: NotFoundView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
  routes,
});

export default router;
