<template>
  <!-- header -->
  <header>
    <div class="container">
      <div class="row align-items-center">
        <div class="col-xl-2">
          <div class="header-style">
            <router-link to="/">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="170"
                height="50"
                viewBox="0 0 170 50"
              >
                <g id="Logo" transform="translate(-260 -51)">
                  <text
                    id=""
                    transform="translate(320 77)"
                    fill="#363636"
                    font-size="35"
                    font-family="Poppins"
                    font-weight="700"
                  >
                    <tspan x="0" y="0">VIC</tspan>
                    <tspan y="0" fill="#f29f05">.LK</tspan>
                  </text>
                </g>
              </svg>
            </router-link>
            <div class="extras bag">
              <div class="bar-menu">
                <i class="fa-solid fa-bars"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-7">
          <nav class="navbar">
            <ul class="navbar-links">
              <li
                class="navbar-dropdown"
                :class="{ active: activeMenu === '/' }"
              >
                <router-link to="/">Home</router-link>
              </li>

              <li
                class="navbar-dropdown"
                :class="{
                  active:
                    activeMenu === '/jobbank' ||
                    activeMenu === '/matrimony' ||
                    activeMenu === '/realestate' ||
                    activeMenu === '/vehicles',
                }"
              >
                <a>Our Services</a>
                <div class="dropdown">
                  <a href="https://jobbank.vic.lk" target="_blank"
                    >VIC Job Bank</a
                  >
                  <router-link to="/matrimony">VIC Matrimony</router-link>
                  <router-link to="/realestate">VIC Realestate</router-link>
                  <router-link to="/vehicles">VIC Vehicles</router-link>
                </div>
              </li>
              <li class="navbar-dropdown">
                <router-link to="#">About Us</router-link>
              </li>
              <li class="navbar-dropdown">
                <router-link to="#">Contacts</router-link>
              </li>
            </ul>
          </nav>
        </div>

        <div
          class="mobile-nav hmburger-menu"
          id="mobile-nav"
          style="display: block"
        >
          <div class="res-log">
            <router-link to="/">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="170"
                height="60"
                viewBox="0 0 163 38"
              >
                <g id="Logo" transform="translate(-260 -51)">
                  <text
                    id=""
                    transform="translate(320 77)"
                    fill="#363636"
                    font-size="20"
                    font-family="Poppins"
                    font-weight="700"
                  >
                    <tspan x="0" y="0">VIC</tspan>
                    <tspan y="0" fill="#f29f05">.LK</tspan>
                  </text>
                </g>
              </svg>
            </router-link>
          </div>
          <ul>
            <li>
              <router-link to="/">Home</router-link>
            </li>
            <li class="menu-item-has-children">
              <a>Our Services</a>
              <ul class="sub-menu">
                <li>
                  <a href="https://jobbank.vic.lk" target="_blank"
                    >VIC Job Bank</a
                  >
                </li>
                <li>
                  <router-link to="/matrimony">VIC Matrimony</router-link>
                </li>
                <li>
                  <router-link to="/realestate">VIC Realestate</router-link>
                </li>
                <li><router-link to="/vehicles">VIC Vehicles</router-link></li>
              </ul>
            </li>
            <li><router-link to="#">About Us</router-link></li>
            <li><router-link to="#">Contact</router-link></li>
          </ul>

          <a href="JavaScript:void(0)" id="res-cross"></a>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import { useRouter, useRoute } from "vue-router";
const location = useRoute();
const router = useRouter();
export default {
  name: "Navigation",
  data() {
    return {
      activeMenu: "/",
    };
  },
  created() {
    this.activeMenu = "/";
    console.log("created");
  },
  methods: {
    changeActiveMenu() {
      // this.activeMenu = $route;
      console.log(this.$router.currentRoute.value.path);
    },
    test() {
      console.log("hiii");
    },
  },
  watch: {
    "$router.currentRoute.value.path"(newPath, oldPath) {
      this.activeMenu = newPath;
    },
  },
};
</script>
