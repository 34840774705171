<template>
  <Navigation />
  <router-view />
  <Footer />
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "HomeView",
  components: { Navigation, Footer },
};
</script>
