<template>
  <!-- footer -->
  <footer class="gap no-bottom" style="background-color: #363636">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-12">
          <div class="footer-description">
            <router-link to="/">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="200"
                height="60"
                viewBox="0 0 200 60"
              >
                <g id="Logo" transform="translate(-260 -51)">
                  <text
                    id=""
                    transform="translate(320 77)"
                    fill="#fff"
                    font-size="35"
                    font-family="Poppins"
                    font-weight="700"
                  >
                    <tspan x="0" y="0">VIC</tspan>
                    <tspan y="0" fill="#f29f05">.LK</tspan>
                  </text>
                </g>
              </svg>
            </router-link>
            <h2 style="font-size: 25px; line-height: 1.5; padding: 10px">
              Your most trusted service provider
            </h2>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="menu contacts">
            <h4>Contacts</h4>
            <div class="footer-location">
              <i class="fa-solid fa-location-dot"></i>
              <p>No. 147, Barns Rathwaththa Mawatha, Balangoda</p>
            </div>
            <a href="mailto:info@vic.lk"
              ><i class="fa-solid fa-envelope"></i>info@vic.lk</a
            >
            <a href="callto:+94775136240"
              ><i class="fa-solid fa-phone"></i>+94 77 51 36 240</a
            >
          </div>
          <ul class="social-media">
            <li>
              <a href="#"><i class="fa-brands fa-facebook-f"></i></a>
            </li>
            <li>
              <a href="#"><i class="fa-brands fa-instagram"></i></a>
            </li>
            <li>
              <a href="#"><i class="fa-brands fa-twitter"></i></a>
            </li>
          </ul>
        </div>
      </div>
      <div class="footer-two gap no-bottom">
        <p>Copyright © 2023. VIC. All rights reserved.</p>
        <div class="privacy">
          <a href="#">Privacy Policy</a>
          <a href="#">Terms & Services</a>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "Footer",
};
</script>
